<template>
    <v-row class="gradient-background" v-if="$vuetify.display.xs || $vuetify.display.sm">
        <v-col cols="4">
          <router-link to="/">
            <v-img to="/" height="100" width="400"  class="mt-4 ml-2" cover src="../assets/gckLogo1.png" ></v-img>
          </router-link>
        </v-col>
        <v-col cols="8" class="text-left">
            <h2 class="mt-4 " style="color: rgb(2, 17, 2);">राजकीय महाविद्यालय करौली</h2>
            <h2 class="" style="color: rgb(5, 75, 5);">Government College Karauli</h2>
        </v-col>
        <v-col cols="12">
            <v-toolbar color="green-darken-3">
                <v-app-bar-nav-icon @click="$store.state.navigationDrawer = true"></v-app-bar-nav-icon>
                <!-- <marquee behavior="scroll" direction="left">
                  <h4>
                    Admission open for session 2023-2024
                  </h4>
                </marquee> -->
            </v-toolbar>
        </v-col>
    </v-row>
    <v-row v-else class="gradient-background">
        <v-col cols="1"></v-col>
        <v-col cols="1">
          <router-link to="/">
            <v-img width="200" height="100" class="mt-3 ml-16" to="/" src="../assets/gckLogo1.png" ></v-img>
          </router-link>
        </v-col>
        <v-col cols="6">
            <h1 class="ml-16 mt-4" style="color: rgb(2, 17, 2);">राजकीय महाविद्यालय करौली</h1>
            <h1 class="ml-16" style="color: rgb(5, 75, 5);">Government College Karauli</h1>
        </v-col>
        <v-col cols="4"></v-col>
        <v-toolbar color="green-darken-3"   >
            <v-spacer></v-spacer>
            <v-btn to="/" stacked>Home</v-btn>
            <v-menu open-on-hover v-model="aboutUsFlag" >
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" height="65" density="compact" :append-icon="aboutUsFlag ? 'mdi-chevron-up':'mdi-chevron-down'">About Us</v-btn>
              </template>
          
              <v-list style="background-color:rgb(242, 255, 242);">
                    <v-list-item @click="updateMenu(item)" v-for="(item, index) in aboutUsMenu" :key="index">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
              </v-list>
            </v-menu>
            <v-menu open-on-hover v-model="academicFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" density="compact" :append-icon="academicFlag ? 'mdi-chevron-up':'mdi-chevron-down'">Academics</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in academicMenu" :key="index">
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
            <v-menu open-on-hover v-model="facultyFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="facultyFlag ? 'mdi-chevron-up':'mdi-chevron-down'">faculty</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in facultyMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="admissionFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="admissionFlag ? 'mdi-chevron-up':'mdi-chevron-down'">Admissions</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in admissionMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="facilitiesFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="facilitiesFlag ? 'mdi-chevron-up':'mdi-chevron-down'">Facilities</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in facilitiesMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="studentCornerFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="studentCornerFlag ? 'mdi-chevron-up':'mdi-chevron-down'">Student Corner</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in studentCornerMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <v-menu open-on-hover v-model="researchFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="researchFlag ? 'mdi-chevron-up':'mdi-chevron-down'">Research</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in researchMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-menu open-on-hover v-model="rti" > -->
                <!-- <template v-slot:activator="{ props }"> -->
                  <v-btn height="65" to="/rti" >RTI</v-btn>
                <!-- </template> -->
            
                <!-- <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in iqacMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu> -->
              <v-menu open-on-hover v-model="iqacFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="iqacFlag ? 'mdi-chevron-up':'mdi-chevron-down'">IQAC</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in iqacMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-menu open-on-hover v-model="reportsFlag" >
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" height="65" :append-icon="reportsFlag ? 'mdi-chevron-up':'mdi-chevron-down'">Reports</v-btn>
                </template>
            
                <v-list style="background-color:rgb(242, 255, 242);">
                      <v-list-item @click="updateMenu(item)" v-for="(item, index) in reportMenu"  :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                </v-list>
              </v-menu> -->
              <v-btn stacked to="/contact" >Contact us</v-btn>
            <v-spacer></v-spacer>
        </v-toolbar>
    </v-row>
    <v-navigation-drawer
        v-model="$store.state.navigationDrawer"
        temporary
        class="gradient-background"
      >
      <v-list-item to="/">
        <v-list-item-title class="ml-2">Home</v-list-item-title>
      </v-list-item>
      <v-expansion-panels>
        <v-expansion-panel elevation="0" class="gradient-background"  title="About us">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in aboutUsMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Academics">
            <v-expansion-panel-text>
                <v-list style="background-color:rgb(242, 255, 242);">
                    <v-list-item @click="updateMenu(item)" v-for="(item, index) in academicMenu"  :key="index">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
              </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Faculty">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in facultyMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Admissions">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in admissionMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Facilities">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in facilitiesMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Student Corner">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in studentCornerMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel elevation="0" class="gradient-background"  title="Research">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in researchMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- <v-list> -->
        <!-- </v-list> -->
        <v-expansion-panel elevation="0" class="gradient-background"  title="IQAC">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in iqacMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- <v-expansion-panel elevation="0" class="gradient-background"  title="Reports">
            <v-expansion-panel-text>
            <v-list style="background-color:rgb(242, 255, 242);">
                <v-list-item @click="updateMenu(item)" v-for="(item, index) in reportMenu" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-expansion-panel-text>
        </v-expansion-panel> -->
      </v-expansion-panels>
      <v-list-item to="/rti">
        <v-list-item-title class="ml-2">RTI</v-list-item-title>
      </v-list-item>
      <v-list-item to="/contact">
        <v-list-item-title class="ml-2">Contact Us</v-list-item-title>
      </v-list-item>
      </v-navigation-drawer>
</template>
<script>
export default {
    data() {
        return {
            aboutUsFlag: false,
            academicFlag: false,
            facultyFlag: false,
            admissionFlag: false,
            facilitiesFlag: false,
            studentCornerFlag: false,
            researchFlag: false,
            iqacFlag: false,
            reportsFlag: false,
            contactFlag: false,
            aboutUsMenu: [
                {title: 'About Us', key:'aboutUs', path:'/about', fullUrl: false},
                // {title: 'Vision And Mission', key:'vison', path:'/vision', fullUrl: false},
                {title: 'Principal Desk', key:'pricipaldesk', path:'/principal_desk', fullUrl: false},
                {title: 'Past Principals', key:'pastpricipal', path:'https://api.govtcollegekarauli.ac.in/principalDates.pdf', fullUrl: true},
                {title: 'Code Of Conduct', key:'cod', path:'/cod', fullUrl: false},
                // {title: 'Organogram', key:'organogram', path:'/organogram', fullUrl: false},
                // {title: 'SWOC - Strength, Weaknesses, Opportunities & Challenges', key:'swoc', path:'/swoc', fullUrl: false},
                {title: 'Events Gallery', key:'events', path:'/events', fullUrl: false},
            ],
            academicMenu: [
                {title: 'Courses Offered', key:'courses', path:'/courses_offered', fullUrl: false},
                {title: 'Course & Program Outcomes', key:'course', path:'/course', fullUrl: false},
                {title: 'Academic Calendar', key:'calendar', path:'https://api.govtcollegekarauli.ac.in/storage/63/Academic-Calendar-2022-23.pdf', fullUrl: true},
                {title: 'Teaching Staff', key:'teachingstaff', path:'/teaching_staff', fullUrl: false},
                {title: 'Non Teaching Staff', key:'nonteachingstaff', path:'/non_teaching_staff', fullUrl: false},
                {title: 'College Committees ', key:'comittees', path:'https://api.govtcollegekarauli.ac.in/storage/722/General-Committe-2024-25.pdf', fullUrl: true},
            ],
            facultyMenu: [
                {title: 'Faculty Of Arts', key:'facultyofarts', path:'/subjects/1', fullUrl: false},
                {title: 'Faculty Of Science', key:'facultyofscience', path:'/subjects/2', fullUrl: false},
                {title: 'Faculty Of Commerce', key:'facultyofcommerce', path:'/subjects/3', fullUrl: false},
            ],
            admissionMenu: [
                {title: 'Admissions', key:'UG', path:'/admissions', fullUrl: false},
                {title: 'Admission Portal Link', key:'admissionPortalLink', path:'https://dceapp.rajasthan.gov.in/', fullUrl: true},
                {title: 'Admission Committee', key:'admissionCommittee', path:'https://api.govtcollegekarauli.ac.in/storage/705/WhatsApp-Image-2024-07-07-at-18.54.04.pdf', fullUrl: true},
                {title: 'Admission Policy', key:'admissionPolicy', path:'https://api.govtcollegekarauli.ac.in/storage/690/admission-policy-2024-2025.pdf', fullUrl: true},
                // {title: 'Admission Prospectus 2023-24', key:'admissionProspectus', path:'https://api.govtcollegekarauli.ac.in/Prospectus+2023-24.pdf', fullUrl: true},
            ],
            studentCornerMenu: [
                {title: 'Circulars', key:'aboutUs', path:'/circular', fullUrl: false},
                {title: 'Scholarships', key:'scholarships', path:'/scholarships', fullUrl: false},
                {title: 'Code Of Ethics', key:'coe', path:'/coe', fullUrl: false},
                // {title: 'E-Resources', key:'resources', path:'/resources', fullUrl: false},
                {title: 'Important Forms', key:'forms', path:'/forms', fullUrl: false},
                {title: 'Syllabus', key:'syllabus', path:'https://www.uok.ac.in/Syllabus-2022', fullUrl: true},
                {title: 'Student Feedback', key:'survey', path:'  https://forms.gle/nkJWwzKJGdJaaxGV7', fullUrl: true},
                // {title: 'MOOC Course', key:'mooc', path:'/mooc', fullUrl: false},
            ],
            facilitiesMenu: [
                {title: 'NSS National Service Scheme', key:'nss', path:'/nss', fullUrl: false},
                {title: 'Sports', key:'sports', path:'/sports', fullUrl: false},
                {title: 'Library', key:'library', path:'/library', fullUrl: false},
                {title: 'Electoral Literacy Club', key:'elc', path:'/elc', fullUrl: false},
                {title: 'Open University Centers', key:'ouc', path:'/ouc', fullUrl: false},
            ],
            /* researchMenu: [
                {title: 'Research Supervisors', key:'supervisors', path:'/supervisors', fullUrl: false},
                {title: 'Research Scholars', key:'scholars', path:'/scholars', fullUrl: false},
                {title: 'Ordinance Related To Research', key:'ordinance', path:'/ordinance', fullUrl: false},
            ], */
            iqacMenu: [
              {title: 'All Data', key:'allData', path:'/iqac_all', fullUrl: false},
              {title: 'Committee', key:'iqCommitee', path:'/iqac/commitee', fullUrl: false},
              {title: 'AQAR', key:'aqar', path:'/iqac/aqar', fullUrl: false},
              {title: 'SSR', key:'ssr', path:'/iqac/ssr', fullUrl: false},
              {title: 'Meeting Minutes', key:'metting_minutes', path:'/iqac/mm', fullUrl: false},
              {title: 'Best Practices', key:'bp', path:'/iqac/best_practices', fullUrl: false},
              {title: 'Academic Calendars', key:'ac', path:'/iqac/calenders', fullUrl: false},
              {title: 'NAAC', key:'ac', path:'/naac', fullUrl: false},
            ],
            reportMenu: [
                {title: 'Academic Activity Reports', key:'iqCommitee', path:'/iqCommitee', fullUrl: false},
                {title: 'NSS Activity Reports', key:'nssActivityReport', path:'/nss_activity_report', fullUrl: false},
            ],
        }
    },
    methods: {
        updateMenu (item) {
          if (item.fullUrl) {
            window.open(item.path)
          } else {
            this.$router.push(item.path)
          }
        }
    },
}
</script>
<style scoped>
.gradient-background {
  background: linear-gradient(to right, #ffffff, #deffce);
}
</style>
