import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import Vue3Marquee from 'vue3-marquee'
import { createHead } from '@vueuse/head'
const head = createHead()

loadFonts()

createApp(App)
  .use(head)
  .use(Vue3Marquee)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')