<template>
    <v-footer   class="bg-blue-grey-darken-3  d-flex flex-column" >
      <v-container>
        <v-row>
          <v-col lg="1"></v-col>
          <v-col lg="2" md="3" sm="6" cols="12">
            <v-img src="../assets/gckLogo1.png" class="mt-4" height="140"></v-img>
            <div class="text-center">
              <p class="mt-5 text-h6">Government College</p>
              <p class="text-h6">Karauli, 322241</p>
              <p class="text-h6">Rajasthan</p>
            </div>
          </v-col>
          <v-col lg="2" md="3" sm="6" cols="12">
            <v-card elevation="0" color="blue-grey-darken-3">
              <v-card-title class="text-left mt-10">Important Links</v-card-title>
              <v-divider width="200" color="green"></v-divider>
              <v-card-text class="text-left">
                <a href="/circular" style="color:rgb(237, 224, 224)" class="text-subtitle-1">Circulars</a> <br><br>
                <a href="/events" style="color:rgb(237, 224, 224)" class="text-subtitle-1">Events</a><br><br>
                <a href="/scholarships" style="color:rgb(237, 224, 224)" class="text-subtitle-1">Scholarships</a><br><br>
                <a href="/departments" style="color:rgb(237, 224, 224)" class="text-subtitle-1">Departments</a>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col lg="3" md="3" sm="6" cols="12">
            <v-card elevation="0" color="blue-grey-darken-3">
              <v-card-title class="text-left mt-10">Nodal Officer</v-card-title>
              <v-divider width="200" color="green"></v-divider>
              <v-card-text class="text-left">
                <p class="mt-5 text-h6">Dr. Mahendra Singh Meena</p>
                <p class="text-subtitle-1 mt-2">Assistant Professor</p>
                <p class="text-subtitle-1 mt-2">Phone No.:+91 8209611595</p>
                <p class="text-subtitle-1 mt-2">Email Id: singhmeena.mahendra@gmail.com</p>
                <p class="text-subtitle-1 mt-2">Fax: 07464250023

                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col lg="3" md="3" sm="6" cols="12">
            <v-card elevation="0" color="blue-grey-darken-3">
              <v-card-title class="text-left mt-10">Social</v-card-title>
              <v-divider width="200" color="green"></v-divider>
              <v-card-text class="text-left">
                <v-btn v-for="icon in icons" :key="icon" @click="navigateFromPage(icon.url)" variant="text"> <v-icon class="mr-2">mdi-facebook</v-icon> Facebook</v-btn>
                <p class="mt-3 text-subtitle-1">
                  Near circuit house, Kailadevi road
                </p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d14282.468612936627!2d77.01561!3d26.500269!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDMwJzAxLjAiTiA3N8KwMDAnNTYuMiJF!5e0!3m2!1sen!2sus!4v1706955217743!5m2!1sen!2sus" width="400" height="200" class="mt-4" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col lg="1"></v-col>
        </v-row>
      </v-container>
        <!-- <v-row>
          <v-col cols="12">
            <v-btn v-for="icon in icons" :key="icon" class="mx-4" :icon="icon.icon" @click="navigateFromPage(icon.url)" variant="text">
            </v-btn>
          </v-col>
          <v-col xl="4" lg="4" md="3" cols="6">
          </v-col>
        </v-row> -->
    
        <div class="mt-4">
          {{ new Date().getFullYear() }} — <strong>Govt. College Karauli. All Right Reserved</strong>
        </div>
      </v-footer>
</template>
<script>
import router from '@/router'
export default {
    data() {
        return {
            icons: [
                {icon: 'mdi-facebook', url: 'https://www.facebook.com/Karauli.college/'},
            ],
        }
    },
    methods: {
      navigateFromPage(url) {
        window.open(url)
      }
    },
}
</script>