<template>
    <v-container>
        <v-row v-if="$vuetify.display.xll || $vuetify.display.xl || $vuetify.display.lg || $vuetify.display.md || $vuetify.display.sm">
            <v-col cols="12" class="text-center">
                <h1 class="font-weight-light">Event Gallery</h1>
                <v-slide-group v-model="model" class="pa-4" show-arrows>
                  <v-slide-group-item v-for="(image, i) in images" :key="i" >
                    <v-card
                    @click="viewImage(image)"
                      color="grey-lighten-1"
                      :class="['ma-4']"
                      width="400"
                      height="300"
                    >
                    <v-img :src="image.original_url" :lazy-src="image.thumb_url"  width="400" height="300" :cover="true" contain  >
                        <template v-slot:placeholder>
                            <div class="d-flex align-center justify-center fill-height">
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                    </v-img>
                    </v-card>
                  </v-slide-group-item>
                </v-slide-group>
            </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="text-center" v-if="!loading">
              <h1 class="font-weight-light">Event Gallery</h1>
              <v-carousel  cycle="" hide-delimiter-background :show-arrows="false" delimiter-icon="mdi-square" height="300"  class="mt-8">
                <v-carousel-item v-for="(image,i) in images" :key="i"  >
                  <v-img :src="image.original_url" cover="" :lazy-src="image.thumb_url" aspect-ratio="16/9"></v-img>
                </v-carousel-item>
              </v-carousel>
              <!-- <v-slide-group v-model="model" :show-arrows="true">
                <v-slide-group-item v-for="(image, i) in images" :key="i" >
                  <v-card
                  @click="viewImage(image)"
                  >
                  <v-img :src="image.original_url" :lazy-src="image.thumb_url"  width="400" height="300"  contain  >
                      <template v-slot:placeholder>
                          <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular
                              color="grey-lighten-4"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                        </template>
                  </v-img>
                  </v-card>
                </v-slide-group-item>
              </v-slide-group> -->
          </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="viewImageDialog" max-width="1000">
        <v-card>
            <v-card-text>
                <v-img :src="selectedImage" :lazy-src="selectedThumbImage" >
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                </v-img>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { APIService } from '../Utils/APIService'
const apiService = new APIService()
export default {
    data() {
        return {
            model: null,
            selectedImage: null,
            selectedThumbImage: null,
            viewImageDialog: false,
            images: [],
            errors: [],
            loading: true
        }
    },
    methods: {
        viewImage (image) {
            this.selectedImage = image.original_url
            this.selectedThumbImage = image.thumb_url
            this.viewImageDialog = true
        },
        getImages() {
            apiService.getImages().then((response) => {
            if (response.status === 200) {
                this.loading = false
                this.images = response.data.data
            }
            })
            .catch(e => {
              this.errors.push(e)
              this.loading = false
            })
        }
    },
    mounted() {
        this.getImages()
    },
}
</script>