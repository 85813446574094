import axios from 'axios'
import GlobalVariable from './GlobalVariable.js'
import Store from '../store/index.js'
const API_URL = GlobalVariable.baseUrl
export class APIService {
    getEvents () {
        const url = `${API_URL}/user/events`
        return axios.get(url)
    }
    getEventDetails (id) {
        const url = `${API_URL}/user/event/${id}`
        return axios.get(url)
    }
    getDepartments () {
        const url = `${API_URL}/user/departments`
        return axios.get(url)
    }
    getImages () {
        const url = `${API_URL}/user/images_all`
        return axios.get(url)
    }
    getCirculars () {
        const url = `${API_URL}/user/circulars`
        return axios.get(url)
    }
    getAdmissionCirculars (page) {
        const url = `${API_URL}/user/circulars/admissions/${page}`
        return axios.get(url)
    }
    getFaculties (id) {
        const url = `${API_URL}/user/faculties/${id}`
        return axios.get(url)
    }
    getDepartmentDetails (id) {
        const url = `${API_URL}/user/department/${id}`
        return axios.get(url)
    }
    getAllFaculties () {
        const url = `${API_URL}/user/faculties_all`
        return axios.get(url)
    }
    getSubDepartments (id) {
        const url = `${API_URL}/user/subjects/${id}`
        return axios.get(url)
    }
    getStaff () {
        const url = `${API_URL}/user/staffs`
        return axios.get(url)
    }
    getBanners () {
        const url = `${API_URL}/user/banner`
        return axios.get(url)
    }
    getScholarships () {
        const url = `${API_URL}/user/scholarships`
        return axios.get(url)
    }
    getIQAC () {
        const url = `${API_URL}/user/iqac`
        return axios.get(url)
    }
    getIQACByType (type) {
        const url = `${API_URL}/user/iqac/${type}`
        return axios.get(url)
    }
    payFee (practicalJson) {
        console.log(practicalJson)
        const url = `${API_URL}/user/practical_fee/pay`
        return axios.post(url, practicalJson)
    }
    getFeeDetails (txnId) {
        const url = `${API_URL}/user/practical_fee/${txnId}`
        return axios.get(url)
    }
    getCriteria () {
        const url = `${API_URL}/user/criteria`
        return axios.get(url)
    }
}

