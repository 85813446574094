<template>
  <v-app style="overflow-x: hidden;">
    <v-main>
      <appBar/>
      <router-view/>
    </v-main>
    <footerBar></footerBar>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue'
import FooterBar from './components/Footer.vue'
import { useHead } from '@vueuse/head'
export default {
  name: 'App',
  components: {
      'appBar': AppBar,
      'footerBar': FooterBar
  },
  useHead:{
      // Can be static or computed
      title: 'Name',
      meta: [
        {
          name: `description`,
          content: 'Government College Karauli',
        },
        ],
     
    },
  data: () => ({
    //
  }),
}
</script>
